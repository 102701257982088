import NotFoundPageModel from './Models/NotFoundPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import HeroModule from '../../Organisms/Hero/HeroModule';
import ContentArea from '../../Organisms/ContentArea/ContentArea';
import Grid from '../../Atoms/Grids/Grid';

function NotFoundPage() {
  const {
    contentArea,
    teaserHeader,
    teaserImage,
    teaserLinks,
    teaserText,
    teaserHeaderSize,
    teaserButtonType,
  } = useCurrentPage<NotFoundPageModel>();

  return (
    <>
      {teaserImage && (
        <HeroModule
          header={teaserHeader}
          summary={teaserText}
          links={teaserLinks}
          image={teaserImage}
          headerSize={teaserHeaderSize}
          buttonType={teaserButtonType}
        />
      )}
      <Grid type={12} data-content-area>
        <ContentArea childItems={contentArea} />
      </Grid>
    </>
  );
}

export default NotFoundPage;
